import './AttractLoop.css';
import Banner from '../Banner/Banner';
import { useEffect, useState } from 'react';
import { BannerDefs } from '../bannerdefs';
import { useInjection } from '../DependancyInjection/DependencyContext';
import DependencyType from '../DependancyInjection/DependencyType';
import { AttractLoopOrchestratorService, AttractLoopState } from '../InteractiveOrchestratorService/InteractiveOrchestratorService';

function AttractLoop() {
  const [attractState, setAttractState] = useState<AttractLoopState | undefined>(undefined);
  const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(DependencyType.AttractLoopOrchestratorService);

useEffect(() => {
  const sub = attractLoopOrchestratorService.observe().subscribe((state) => {
    console.info(`AttractLoopOrchestratorService state`, state);
    setAttractState(state);
  });

  return () => {
    sub.unsubscribe();
  };
}, [attractLoopOrchestratorService]);

  const requestStopFromInteraction = () => {
    attractLoopOrchestratorService.requestStopFromInteraction();
  }

  const playerVisible = attractState?.currentBanner.display ?? false;

  return (
    <div className={`AttractLoop ${playerVisible ? "AttractLoop__visible" : "AttractLoop__hidden"}`} onClick={requestStopFromInteraction}>
      {attractState?.availableBanners.map(banner => {
        return <Banner key={banner.id} def={banner} playerVisible={playerVisible} animating={attractState?.currentBanner.id === banner.id}/>
      })}
      <div className={`Logo`}>
        <img src="https://imagedelivery.net/-f5bUQJUthKVRJ-ta9_Rcg/01J1FCN416CXY65K7H1VCPZSFX/public" alt="logo" />
      </div>
    </div>
  );
}

export default AttractLoop;
