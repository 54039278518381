import { Observable, Subject } from 'rxjs';
import { BannerDef, BannerDefs } from '../bannerdefs';

export interface AttractLoopState {
    availableBanners: BannerDef[];
    currentBanner : {
        id?: string;
        index?: number;
        display: boolean;
    }
}

type configServiceConfigType = {
    displayMode: 'noBanners' | 'bannersUntilInteraction' | 'bannersAndHome';
    showEverySeconds?: number,
    durationSeconds?: number,
}

export class AttractLoopOrchestratorService {
    private _interval?: NodeJS.Timeout;
    private _subject: Subject<AttractLoopState> = new Subject();
    private _availableBanners: BannerDef[] = [];
    private _displayState: AttractLoopState = {
        availableBanners: [],
        currentBanner: {
            display: false,
        },
    };
    private _shouldDisplayBanner = false;
    private _wentToBannerLastTime = false;
    private _nextAllowedAt?: Date;
    private _shouldForceClose = false;


    private _configServiceConfig: configServiceConfigType = {
        displayMode: 'bannersUntilInteraction',
        showEverySeconds: 5,
        durationSeconds: 5,
    };
    
    constructor() {
        this._availableBanners = BannerDefs;
        this._displayState.availableBanners = BannerDefs;
        this.setupQAHelpers();
    }

    setupQAHelpers() {
        (window as any).qaHelper_AttractLoopOrchestratorServiceToggle = () => {
            if (this._interval) {
                this.stop();
                console.info('Asked AttractLoopOrchestratorServiceToggle to stop');
            } else {
                this.start();
                console.info('Asked AttractLoopOrchestratorServiceToggle to start');
            }
        };
    }

    requestStopFromInteraction() {
        this._shouldForceClose = true;
    }
    
    start() {
        console.info(`Start Called`);
        if (this._interval) {
            console.info('AttractLoopOrchestratorServiceToggle, start called but already started');
            return;
        }

        if (this._availableBanners.length === 0) {
            console.info('InteractiveOrchestratorService, start called but no categories');
            this._subject.next(this._displayState);
            return;
        }
        this._displayState.currentBanner = {
            display: false
        };
        this._nextAllowedAt = new Date();

        this._interval = setInterval(() => {
            this.handleNext();
        }, 50);
    }

    stop() {
        console.info(`Stop Called`);
        if (this._interval) {
            clearInterval(this._interval);
        }
        this._interval = undefined;
    }

    observe(): Observable<AttractLoopState> {
        return this._subject.asObservable();
    }

    async reportBannerAnimatingOut() {
        console.log('A banner told AttractLoopOrchestratorService that it was animating out, so we can start the next animation')
        this._nextAllowedAt = new Date();
    }

    async handleNext() {
        try {
            const newState: AttractLoopState = {
                ...this._displayState,
                currentBanner: {
                    ...this._displayState.currentBanner,
                },
            };

            if (
                this._configServiceConfig.displayMode === 'noBanners' ||
                this._availableBanners.length === 0
            ) {
                //We should never show banners, so lets just return and do nothing.
                console.log(`No banners to show`);
                return;
            }

            if(this._shouldForceClose && this._displayState.currentBanner.display) {
                console.log(`Forcing close`);
                newState.currentBanner.display = false;
                this._subject.next(newState);
                this.stop();
                return;
            }

            if (this._nextAllowedAt && this._nextAllowedAt > new Date()) {
                return;
            }

            console.log('Handle next was allowed');
            let timeModifier = (this._configServiceConfig.showEverySeconds ?? 1) * 1000;

            if (
                this._configServiceConfig.displayMode === 'bannersUntilInteraction'
            ) {
                //We should always be showing a banner here.
                this._shouldDisplayBanner = true;

                timeModifier = (this._configServiceConfig.durationSeconds ?? 1) * 1000;
            } else if (
                this._configServiceConfig.displayMode === 'bannersAndHome'
            ) {
                //We might need to show a banner here, or we might not.
                //This cloudshelf should show banner, then attract, then banner, then attract, in a loop.
                if (this._shouldDisplayBanner) {
                    //We were displaying a banner previously, so we shouldn't now.
                    this._shouldDisplayBanner = false;
                } else {
                    //We were not showing a banner last time, so we should this time.
                    this._shouldDisplayBanner = true;
                }

                if (this._wentToBannerLastTime) {
                    timeModifier =
                        (this._configServiceConfig.showEverySeconds ?? 1) * 1000;
                    this._wentToBannerLastTime = false;
                } else {
                    timeModifier = (this._configServiceConfig.durationSeconds ?? 1) * 1000;
                    this._wentToBannerLastTime = true;
                }
            }

            //lets build a new state, and set the the flag for displaying banners
            newState.currentBanner.display = this._shouldDisplayBanner;
            //Now lets move to the next banner in the list (or back to the start if we are at the end).
            const nextBannerIndex = (this._displayState.currentBanner?.index ?? -1) + 1;
            console.log(`nextBannerIndex:`, nextBannerIndex);
            console.log(`_shouldDisplayBanner:`, this._shouldDisplayBanner);
            //
            if (this._shouldDisplayBanner) {
                if((this._displayState.availableBanners.length - 1) >= nextBannerIndex) {
                    newState.currentBanner.index = nextBannerIndex;
                    newState.currentBanner.id = this._displayState.availableBanners[nextBannerIndex].id;
                } else {
                    newState.currentBanner.index = 0;
                    newState.currentBanner.id = this._displayState.availableBanners[0].id;
                }
            }

            //Let's set the new state
            this._displayState = newState;

            //Let's set the time for the next update
            this._nextAllowedAt = new Date(Date.now() + timeModifier + 3600000); // Adding 1 hour in milliseconds

            //And finally let the subscribers know that we have updated the display state.
            this._subject.next(this._displayState);
        } catch (err) {
            console.log('Error in AttractLoopOrchestratorService', err);
        }
    }


}