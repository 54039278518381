import AttractLoop from '../AttractLoop/AttractLoop';
import { useInjection } from '../DependancyInjection/DependencyContext';
import DependencyType from '../DependancyInjection/DependencyType';
import { AttractLoopOrchestratorService, AttractLoopState } from '../InteractiveOrchestratorService/InteractiveOrchestratorService';
import './TestPage.css';
import { useEffect, useState } from 'react';

function TestPage() {
  const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(DependencyType.AttractLoopOrchestratorService);
const [message, setMessage] = useState("Welcome to the Test Page");
const [attractState, setAttractState] = useState<AttractLoopState | undefined>(undefined);


useEffect(() => {
  const sub = attractLoopOrchestratorService.observe().subscribe((state) => {
    setAttractState(state);
  });
  return () => {
    sub.unsubscribe();
  };
}, [attractLoopOrchestratorService]);

return (
  <div className="TestPage">
    <h1 style={{ fontSize: 36, color: 'blue' }}>{message}</h1>
    <button style={{ backgroundColor: 'green', color: 'white', padding: 10, borderRadius: 5 }} onClick={() => setMessage("Button clicked!")}>Click Me</button>
    <p>This is a paragraph of text.</p>
    <ul>
      <li>Item 1</li>
      <li>Item 2</li>
      <li>Item 3</li>
    </ul>
    <button  style={{ backgroundColor: 'green', color: 'white', padding: 10, borderRadius: 5 }} onClick={() => attractLoopOrchestratorService.start()}>Start Attract Loop</button>
    <button  style={{ backgroundColor: 'green', color: 'white', padding: 10, borderRadius: 5 }} onClick={() => attractLoopOrchestratorService.stop()}>Stop Attract Loop</button>
    <hr/>
    <p>Number of Banners Available: <strong>{attractState?.availableBanners.length}</strong></p>
    <p>Banner should be visible: <strong>{attractState?.currentBanner.display === true ? 'YES' : 'NO'}</strong></p>
    <p>Current Banner Index (0-based): <strong>{attractState?.currentBanner.index}</strong></p>
    <pre>{JSON.stringify(attractState?.currentBanner, null, 2)}</pre>
    <AttractLoop />
  </div>
);


}

export default TestPage;
