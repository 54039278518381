import React, { useEffect, useRef, useState } from 'react';
import './Banner.css';
import { BannerDef } from '../bannerdefs';
import gsap  from 'gsap';
import { useGSAP } from '@gsap/react';
import { GSDevTools } from "gsap/GSDevTools";
import { useInjection } from '../DependancyInjection/DependencyContext';
import { AttractLoopOrchestratorService } from '../InteractiveOrchestratorService/InteractiveOrchestratorService';
import DependencyType from '../DependancyInjection/DependencyType';

interface BannerProps {
  def: BannerDef;
  playerVisible: boolean;
  devControls?: boolean;
  animating: boolean;
}

const Banner: React.FC<BannerProps> = (props) => {
  const tlRef = useRef<GSAPTimeline | null>(null);
  const container = useRef(null);
  const firstText = props.def.text.length > 0 ? props.def.text[0] : "";
  const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(DependencyType.AttractLoopOrchestratorService);
  const [headingOut, setHeadingOut] = useState(false);
  const [animatedAtLeastOnce, setAnimatedAtLeastOnce] = useState(false);

  const reportBannerAnimatingOut = () => {
    attractLoopOrchestratorService.reportBannerAnimatingOut();
    setHeadingOut(true);
  }

  const finsihedOut = () => {
    setHeadingOut(false);
  }

  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

  const isLandscape = screenWidth > screenHeight;

  const InBackgroundImageFromVars: GSAPTweenVars = {
    opacity: 0,
    [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2) : screenHeight * 0.2,
  }

  const InBackgroundImageToVars: GSAPTweenVars = {
    opacity: 1,
    [isLandscape ? 'x' : 'y']: 0,
    duration: 1
  }

  const InTextAreaFromVars: GSAPTweenVars = {
    opacity: 0,
    [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.75) : screenHeight * 0.75,
  }

  const InTextAreaToVars: GSAPTweenVars = {
    opacity: 1,
    [isLandscape ? 'x' : 'y']: 0,
    duration: 2
  }

  const InButtonAreaFromVars: GSAPTweenVars = {
    opacity: 0,
    [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2) : screenHeight * 0.2,
  }

  const InButtonAreaToVars: GSAPTweenVars = {
    opacity: 1,
    [isLandscape ? 'x' : 'y']: 0,
    duration: 2
  }

  const OutBackgroundImageFromVars: GSAPTweenVars = {
    opacity: 1,
    [isLandscape ? 'x' : 'y']: 0,
  }

  const OutBackgroundImageToVars: GSAPTweenVars = {
    opacity: 0,
    [isLandscape ? 'x' : 'y']: isLandscape ? screenWidth * 0.2  : -(screenHeight * 0.2),
    duration: 1
  }

  const OutButtonAreaFromVars: GSAPTweenVars = {
    opacity: 1,
    [isLandscape ? 'x' : 'y']: 0,
  }

  const OutButtonAreaToVars: GSAPTweenVars = {
    opacity: 0,
    [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2)  : screenHeight * 0.2,
    duration: 1
  }

  const OutTextAreaFromVars: GSAPTweenVars = {
    opacity: 1,
    [isLandscape ? 'x' : 'y']: 0,
  }

  const OutTextAreaToVars: GSAPTweenVars = {
    opacity: 0,
    [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2)  : screenHeight * 0.2,
    duration: 1
  }
  useGSAP(
    () => {
      const tl = gsap.timeline({id: props.def.id, defaults: {ease: 'power2.inOut'}, onComplete: () => console.log(`timeline ${props.def.id} complete`)})
      .addLabel('in')
      .to({}, {duration: 0.25})
      .fromTo(".Banner__BackgroundImage", InBackgroundImageFromVars, InBackgroundImageToVars);

      if(props.def.text.length > 0) {
        tl.fromTo(".Banner__TextArea", InTextAreaFromVars, InTextAreaToVars, "-=0.50")
      }

      if(props.def.button) {
        let pos = '<';
        if(props.def.text.length === 0) {
          pos = "-=0.50";
        }

        tl.fromTo(".Banner__ButtonArea", InButtonAreaFromVars, InButtonAreaToVars, pos)
      }

      if(props.def.text.length > 1) {
        props.def.text.slice(1).forEach((text: string) => {

          tl
          .to({}, {duration: 2})
          .to(".Banner__TextArea", { duration: 0.75, text: ''})
          .to(".Banner__TextArea", { duration: 1.25, text: text});
        });
      }
      
      //always then wait at least 2 seconds, before doing out.
      tl.to({}, {duration: 2});
      
      const ruleFromConfig = 5;
      const additionalPause = ruleFromConfig - tl.duration();
      if(additionalPause > 0) {
        tl.to({}, {duration: additionalPause});
      }

      tl.addLabel('out');
      //Tell the serivce that we are going to animate out, so that it can then animate in, and then start the animation.
      tl.call(reportBannerAnimatingOut);
      tl.to({}, {duration: 0.5});
    
      tl.fromTo(".Banner__BackgroundImage", OutBackgroundImageFromVars, OutBackgroundImageToVars);

      if(props.def.button) {
        tl.fromTo(".Banner__ButtonArea", OutButtonAreaFromVars, OutButtonAreaToVars, '<')
      }

      if(props.def.text.length !== 0) {
        tl.fromTo(".Banner__TextArea", OutTextAreaFromVars, OutTextAreaToVars, "-=0.50")
      }

      
      tl.call(finsihedOut)
      tl.addLabel('end');
      tl.pause(0);

      //Finally, we set the timeline reference, so we can pause it later, and then setup dev controls if we need to.
      tlRef.current = tl;
      if(props.devControls) {
        GSDevTools.create({paused: true});
      }
    },
    { scope: container }
  );

  useEffect(() => {
    if(!props.playerVisible) {
      console.log('Banner told ParentPlayer is hidden.', tlRef.current);
      tlRef.current?.pause();
    }
  }, [props.playerVisible]);

  useEffect(() => {
    if(props.animating) {
      console.log(`Banner '${props.def.id}' aked to animate.`);
      setHeadingOut(false);
      setAnimatedAtLeastOnce(true);
      tlRef.current?.restart(false, true);
    } 
  }, [props.animating]);

  return (
    <div className={`Banner ${props.animating ? 'BannerIn' : ''} ${headingOut ? 'BannerOut' : ''}`} ref={container}>
      <div className={`Banner__BackgroundImage  ${animatedAtLeastOnce ? '' : 'AnimationOpacityFix'}`}>
        <img src={props.def.backgroundImage} className='Banner__BackgroundImage__Blur'/>
        <img src={props.def.backgroundImage} className='Banner__BackgroundImage__Image'/>
      </div>
      <div className={`Banner__TextArea  LargeText ${animatedAtLeastOnce ? '' : 'AnimationOpacityFix'}`}>
        {firstText}
      </div>
      <div className={`TouchRipple`} style={{ paddingBottom: '0.75em' }}>
            <div className={'pulse-circle'} />
        </div>
      {props.def.button && (
        <div className={`Banner__ButtonArea ${animatedAtLeastOnce ? '' : 'AnimationOpacityFix'}`}>
          <div className='Banner__ButtonArea__Button'>
            {props.def.button.text}
          </div>
        </div>
      )}
  
    </div>
  );
}
export default Banner;
