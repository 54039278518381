export type BannerDef = {
  id: string;
  backgroundImage: string;
  text: string[];
  button?: {
    text: string;
  };
};

export const BannerDefs: BannerDef[] = [
  {
    id: '01J1Q32NH4EFGS9W260Y29J26J',
    backgroundImage: '/img/mountain.jpeg',
    text: ["New", "Summer Collection"],
    button: {
      text: "Book NOW!",
    }
  },
  {
    id: '01J1Q32GDHEV7J3ZW4E80M42DD',
    backgroundImage: '/img/waterfall.jpeg',
    text: ["2nd banner"],
    button: {
      text: "See more waterfalls",
    }
  }, 
  {
    id: '01J1Q32GDHEV7J3ZW4E80M42DE',
    backgroundImage: '/img/image (22).png',
    text: ["3rd Banner, no button"],
  }, 
];
