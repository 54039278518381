import { Container } from 'inversify';
import DependencyType from './DependencyType';
import { AttractLoopOrchestratorService } from '../InteractiveOrchestratorService/InteractiveOrchestratorService';

export const dependenciesContainer = new Container();

export const initDependencies = async (): Promise<void> => {
    dependenciesContainer
        .bind<AttractLoopOrchestratorService>(DependencyType.AttractLoopOrchestratorService)
        .toConstantValue(new AttractLoopOrchestratorService());
};
